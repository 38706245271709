import { Culture } from "@/enums";
import {
  getCultureFromLocalStorage,
  setCultureInLocalStorage,
} from "@/services/culture.service";
import HttpClient from "@/utils/httpClient";
import {
  Module,
  ActionTree,
  GetterTree,
  MutationTree,
  ActionContext,
} from "vuex";
import { RootState } from "..";

export interface CultureStoreState extends Record<string, unknown> {
  active: Culture;
  available: Culture[];
}

const AVAILABLE_CULTURES = [Culture.NL, Culture.EN, Culture.FR];
const DEFAULT_CULTURE = Culture.NL;

const initializeStoreState = (): CultureStoreState => {
  const storedCulture =
    getCultureFromLocalStorage() || getBrowserDefaultCulture();
  const cultureIsValid = AVAILABLE_CULTURES.includes(storedCulture as Culture);
  const initCulture = cultureIsValid
    ? (storedCulture as Culture)
    : DEFAULT_CULTURE;

  setCulture(initCulture);

  return {
    active: initCulture,
    available: AVAILABLE_CULTURES,
  };
};

const getBrowserDefaultCulture = (): Culture | undefined =>
  AVAILABLE_CULTURES.filter(
    (culture) => culture.substring(0, 2) === navigator.language.substring(0, 2),
  )[0];

const setCulture = (newCulture: Culture) => {
  setCultureInLocalStorage(newCulture);
  HttpClient.setCulture(newCulture);
};

interface CultureStoreActions extends ActionTree<CultureStoreState, RootState> {
  setActive(
    context: ActionContext<CultureStoreState, RootState>,
    culture: Culture
  ): void;
}

const actions: CultureStoreActions = {
  setActive(context, culture) {
    setCulture(culture);
    context.commit("setActive", culture);
  },
};

interface CultureStoreGetters extends GetterTree<CultureStoreState, RootState> {
  active: (state: CultureStoreState) => Culture;
  available: (state: CultureStoreState) => Culture[];
}

const getters: CultureStoreGetters = {
  active: (state) => state.active,
  available: (state) => state.available,
};

interface CultureStoreMutations extends MutationTree<CultureStoreState> {
  setActive(state: CultureStoreState, culture: Culture): void;
}

const mutations: CultureStoreMutations = {
  setActive(state, culture) {
    state.active = culture;
  },
};

export interface CultureStore extends Module<CultureStoreState, RootState> {
  namespaced: true;
  state?: CultureStoreState;
  actions: CultureStoreActions;
  mutations?: CultureStoreMutations;
  getters: CultureStoreGetters;
}

const cultureModule: CultureStore = {
  namespaced: true,
  state: initializeStoreState(),
  getters,
  actions,
  mutations,
};

export default cultureModule;
